<template>
  <div>
    <a-card :bordered="false" v-if="queryParam.inspect_id">
      <div class="card-title">{{ inspect_number }} - {{ inspect_type == 1 ? '抽查车辆' : '检查车辆' }}</div>
      <div class="table-operator">
        <a-button @click="handleRefresh">刷新列表</a-button>
        <a-row :gutter="10" style="margin-top: 10px">
          <a-form layout="horizontal" labelAlign="left">
            <a-col :span="12">
              <a-form-item label="审核状态" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-select
                    allowClear
                    show-search
                    v-model="queryParam.status"
                    placeholder="请选择审核状态"
                    @change="handleRefresh(true)"
                >
                  <a-select-option v-for="item in statusList" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-form>
        </a-row>
      </div>
      <div style="width: 100%;overflow-x: auto">
        <s-table
            ref="table"
            rowKey="iv_id"
            :loading="isLoading"
            :columns="columns"
            :data="loadData"
            :pageSize="15"
            tableLayout="auto"
            :scroll="{x:true}"
        >
          <div slot="days" slot-scope="text, item">
            {{ text }}天
          </div>
          <div slot="status" slot-scope="text, item">
            <a-tag v-if="text===0">未检查</a-tag>
            <a-tag v-if="text===1" color="orange">待审核</a-tag>
            <a-tag v-if="text===2" color="green">审核通过</a-tag>
            <a-tag v-if="text===3" color="red">审核驳回</a-tag>
            <a-tag v-if="text===4">故障未检查</a-tag>
            <a-tag v-if="text===5" color="orange">故障待审核</a-tag>
            <a-tag v-if="text===6" color="green">故障审核通过</a-tag>
            <a-tag v-if="text===7" color="red">故障审核驳回</a-tag>
            <a-tag v-if="text===8" color="blue">已完成检查</a-tag>


            <a-tag v-if="text===9">需整改</a-tag>
            <a-tag v-if="text===10" color="orange">整改待审核</a-tag>
            <a-tag v-if="text===11" color="green">整改审核通过</a-tag>
            <a-tag v-if="text===12" color="red">整改审核驳回</a-tag>
          </div>
          <div class="actions" slot="action" slot-scope="text, item">
            <a v-if="inspect_type == 1 && item.status==0" v-action:choucha @click="showInspectForm(item)">{{ '抽查' }}</a>
            <a v-action:audit @click="handleAudit(item)">{{ inspect_type == 1 ? '查看详情' : '查看审核' }}</a>
            <a v-action:inspectVehicle_delete @click="handleDelete(item)">删除</a>
          </div>
        </s-table>
      </div>
      <AuditForm :inspect_type="inspect_type" ref="AuditForm" @handleSuccess="handleRefresh"/>
      <InspectForm :inspect_type="inspect_type" ref="InspectForm" @handleSubmit="handleRefresh"/>
    </a-card>
    <div v-else>
      <a-card :bordered="false">
        <div class="card-title">车辆审核</div>
        <div class="table-operator">
          <a-button @click="handleRefresh">刷新列表</a-button>
          <a-row :gutter="10" style="margin-top: 10px">
            <a-form layout="horizontal" labelAlign="left">
              <a-col :span="12">
                <a-form-item label="审核状态" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-select
                      allowClear
                      show-search
                      v-model="queryParam.status"
                      placeholder="请选择审核状态"
                      @change="handleRefresh(true)"
                  >
                    <a-select-option v-for="item in statusList" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-form>
          </a-row>
        </div>
        <a-table
            ref="table"
            rowKey="iv_id"
            :loading="isLoading"
            :columns="columns"
            :data="[]"
            :pageSize="15"
            :scroll="{y:550}"
        />
      </a-card>
    </div>
  </div>
</template>

<script>
import * as Api from '@/api/inspect/inspectVehicle'
import {STable} from '@/components'
import AuditForm from './modules/AuditForm.vue'
import SearchForm from './modules/SearchForm.vue'
import InspectForm from './modules/InspectForm.vue'

export default {
  name: 'Index',
  props: {
    inspect_type: {
      type: Number,
      default: 0
    }
  },
  components: {
    STable,
    AuditForm,
    SearchForm,
    InspectForm
  },
  data() {
    return {
      inspect_number: '',
      // 查询参数
      queryParam: {
        status: -1,
        inspect_id: ''
      },

      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},
      statusList: [
        {label: '全部', value: -1},
        {label: '未检查', value: 0},
        {label: '待审核', value: 1},
        {label: '审核通过', value: 2},
        {label: '审核驳回', value: 3},
        {label: '故障未检查', value: 4},
        {label: '故障待审核', value: 5},
        {label: '故障审核通过', value: 6},
        {label: '故障审核驳回', value: 7},
        {label: '已完成检查', value: 8},

        {label: '需整改', value: 9},
        {label: '整改待审核', value: 10},
        {label: '整改审核通过', value: 11},
        {label: '整改审核驳回', value: 12},
      ],
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          width: 60,
          title: '状态',
          dataIndex: 'nstatus',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '车号',
          dataIndex: 'vehicle.vehicle_name',
          width: 100,
          align: 'center',
        },
        {
          width: 100,
          title: this.inspect_type == 1 ? '抽查人员' : '检查人员',
          dataIndex: this.inspect_type == 1 ? 'storeuser.real_name' : 'personnel.personnel_name',
          align: 'center',
        },
        {
          width: 100,
          title: this.inspect_type == 1 ? '抽查时间' : '检查时间',
          dataIndex: this.inspect_type == 1 ? 'audit_time' : 'inspect_time',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleAudit(item) {
      this.$refs.AuditForm.open(item)
    },
    showInspectForm(item) {
      this.$refs.InspectForm.open(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({iv_id: item['iv_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$nextTick(() => {
        this.$refs.table.refresh(bool)
      })
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
