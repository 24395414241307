<template>
  <a-modal
      title="查看审核"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      okText="下载当前文件"
      @ok="download"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div ref="picture">
        <div class="title">{{ title }}</div>
        <div v-if="!isEmpty(storeInfo.seal_file)" style="position: absolute;right:20px">
          <img :src="storeInfo.seal_file.external_url" style="width: 160px;height: 160px">
        </div>
        <div class="table">
          <div class="tr">
            <div class="td f600">检查记录详情</div>
          </div>
          <div class="tr">
            <div class="td f600">项目分类</div>
            <div class="td f600">项目名称</div>
            <div class="td f600">详情</div>
            <div class="td f600">拍照/视频</div>
          </div>
          <div class="tr" v-for="item in record.ic_list">
            <div class="td" style="border-right: none">{{ item.category_name }}</div>
            <div class="td">
              <div class="std" v-for="i2 in item.inspect_list" style="border-left: 1px #d9d9d9 solid">{{
                  i2.project_name
                }}
              </div>
            </div>
            <div class="td">
              <div class="std" v-for="i2 in item.inspect_list">
                <div class="sstd">
                  <div style="border-right: 1px #d9d9d9 solid">
                    完好
                    {{ i2.check == 0 ? '✓' : '' }}
                  </div>
                  <div>
                    故障
                    {{ i2.check == 1 ? '✓' : '' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="td">
              <div class="std" v-for="i2 in item.inspect_list">
                <div class="sstd">
                  <template v-for="img in i2.file_list">
                    <a
                        v-if="isVideo(img.file_ext)"
                        target="_blank"
                        :href="img.external_url"
                    >
                      <div class="image" :style="'background-image:url('+img.preview_url+')' "></div>
                    </a>
                    <a
                        v-else
                        target="_blank"
                        :href="img.external_url"
                    >
                      <div class="image" :style="'background-image:url('+img.external_url+')' "></div>
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inspect_type!=1" class="tr">
            <div class="td f600">检查视频</div>
            <div class="td video" style="display: flex;justify-content: center">
              <template v-for="img in record.file_list">
                <a
                    v-if="isVideo(img.file_ext)"
                    target="_blank"
                    :href="img.external_url"
                >
                  <div class="image" :style="'background-image:url('+img.preview_url+')' "></div>
                </a>
                <a
                    v-else
                    target="_blank"
                    :href="img.external_url"
                >
                  <div class="image" :style="'background-image:url('+img.external_url+')' "></div>
                </a>
              </template>
            </div>
          </div>
          <div class="tr">
            <div class="td f600">检查情况报告</div>
            <div class="td">良好无需整改 {{ errlist.length == 0 ? '✓' : '' }}</div>
            <div class="td">故障需处理 {{ errlist.length > 0 ? '✓' : '' }}</div>
          </div>
          <div class="tr">
            <div class="td f600">需整改内容</div>
            <div class="td">{{ errlist.join(' , ') }}</div>
          </div>

          <template v-if="inspect_type!=1">
            <div class="tr">
              <div class="td f600">检查人</div>
              <div class="td">{{ record.personnel ? record.personnel.personnel_name : '' }}</div>
              <div class="td f600">检查时间</div>
              <div class="td">{{ record.inspect_time }}</div>
              <div class="td f600">检查地点</div>
              <div class="td">{{ record.inspect_address }}</div>
            </div>
            <div class="tr">
              <div class="td f600">审核人</div>
              <div class="td">{{ record.storeuser ? record.storeuser.real_name : '' }}</div>
              <div class="td f600">审核状态</div>
              <div class="td">
                <span v-if="record.status==0">未检查</span>
                <span v-if="record.status==1">待审核</span>
                <span v-if="record.status==2" style="color: #04be02">审核通过</span>
                <span v-if="record.status==3" style="color: red">审核未通过</span>
              </div>
              <div class="td f600">审核时间</div>
              <div class="td">{{ record.audit_time }}</div>
            </div>
            <div class="tr">
              <div class="td f600">审核备注</div>
              <div class="td">{{ record.remarks }}</div>
            </div>
          </template>
          <template v-if="inspect_type==1">
            <div class="tr">
              <div class="td f600">当前状态</div>
              <div class="td">
                <a-tag v-if="record.status===9">需整改</a-tag>
                <a-tag v-if="record.status===10" color="orange">整改待审核</a-tag>
                <a-tag v-if="record.status===11" color="green">整改审核通过</a-tag>
                <a-tag v-if="record.status===12" color="red">整改审核驳回</a-tag>

                <a style="color: red">{{ record.remarks ? record.remarks : '' }}</a>
              </div>
            </div>

            <div class="tr">
              <div class="td f600">抽查人员</div>
              <div class="td">{{ record.storeuser ? record.storeuser.real_name : '' }}</div>
              <div class="td f600">抽查时间</div>
              <div class="td">{{ record.audit_time }}</div>
            </div>
            <div class="tr">
              <div class="td f600">是否罚款</div>
              <div class="td">{{ record.is_penalty ? "是" : '否' }}</div>
              <template v-if="record.is_penalty">
                <div class="td f600">罚款金额</div>
                <div class="td"><span style="color: red">￥</span>{{ record.penalty_amount }}</div>
              </template>
            </div>
            <div class="tr" v-if="record.is_penalty">
              <div class="td f600">罚款事由</div>
              <div class="td">{{ record.penalty_text }}</div>
            </div>
          </template>
        </div>
        <template v-if="record.status>=10">
          <div class="table" style="margin-top: 10px">
            <div class="tr">
              <div class="td f600">整改照片</div>
              <div class="td video" style="display: flex;justify-content: center">
                <template v-for="img in record.rect_file_list">
                  <a
                      v-if="isVideo(img.file_ext)"
                      target="_blank"
                      :href="img.external_url"
                  >
                    <div class="image" :style="'background-image:url('+img.preview_url+')' "></div>
                  </a>
                  <a
                      v-else
                      target="_blank"
                      :href="img.external_url"
                  >
                    <div class="image" :style="'background-image:url('+img.external_url+')' "></div>
                  </a>
                </template>
              </div>
            </div>
            <div class="tr">
              <div class="td f600">备注</div>
              <div class="td video" style="display: flex;justify-content: center">
                {{ record.rect_remarks }}
              </div>
            </div>
          </div>

          <div style="text-align: center;margin-top: 20px" v-if="record.status==10">
            <a-textarea
                v-model="remarks"
                placeholder="输入审核备注"
                :auto-size="{ minRows: 6, maxRows: 6 }"
            />
            <div style="margin-top: 10px">
              <a-button @click="handleAudit(11)" type="primary" style="margin-right: 10px">审核通过</a-button>
              <a-button @click="handleAudit(12)" type="danger">审核驳回</a-button>
            </div>
          </div>
        </template>
        <div style="text-align: center;margin-top: 20px" v-if="record.status==1">
          <a-textarea
              v-model="remarks"
              placeholder="输入审核备注"
              :auto-size="{ minRows: 6, maxRows: 6 }"
          />
          <div style="margin-top: 10px">
            <a-button @click="handleAudit(2)" type="primary" style="margin-right: 10px">审核通过</a-button>
            <a-button @click="handleAudit(3)" type="danger">审核驳回</a-button>
          </div>
        </div>
        <div class="table" style="margin-top: 10px"
             v-if="record.status===2 && record.is_fault===1 && record.fault_status>0">
          <div class="tr">
            <div class="td f600">故障整改处理记录</div>
          </div>
          <div class="tr">
            <div class="td f600">项目分类</div>
            <div class="td f600">项目名称</div>
            <div class="td f600">拍照/视频</div>
          </div>
          <div class="tr" v-for="item in record.fault_list">
            <div class="td" style="border-right: none">{{ item.category_name }}</div>
            <div class="td">
              <div class="std" v-for="i2 in item.inspect_list" style="border-left: 1px #d9d9d9 solid">{{
                  i2.project_name
                }}
              </div>
            </div>
            <div class="td">
              <div class="std" v-for="i2 in item.inspect_list">
                <div class="sstd">
                  <template v-for="img in i2.file_list">
                    <a
                        v-if="isVideo(img.file_ext)"
                        target="_blank"
                        :href="img.external_url"
                    >
                      <div class="image" :style="'background-image:url('+img.preview_url+')' "></div>
                    </a>
                    <a
                        v-else
                        target="_blank"
                        :href="img.external_url"
                    >
                      <div class="image" :style="'background-image:url('+img.external_url+')' "></div>
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="tr">
            <div class="td f600">故障处理时间</div>
            <div class="td ">{{ record.fault_inspect_time }}</div>
            <div class="td f600">审核人</div>
            <div class="td">{{ record.faultstoreuser ? record.faultstoreuser.real_name : '' }}</div>

          </div>

          <div class="tr">
            <div class="td f600">审核状态</div>
            <div class="td">
              <span v-if="record.fault_status==0">未检查</span>
              <span v-if="record.fault_status==1">待审核</span>
              <span v-if="record.fault_status==2" style="color: #04be02">审核通过</span>
              <span v-if="record.fault_status==3" style="color: red">审核未通过</span>
            </div>
            <div class="td f600">审核时间</div>
            <div class="td ">{{ record.fault_audit_time }}</div>
          </div>
          <div class="tr">
            <template v-if="record.fault_status>1">
              <div class="td f600">审核备注</div>
              <div class="td">{{ record.fault_remarks }}</div>
            </template>
            <template v-if="record.fault_status==1">
              <div class="td" style="padding: 10px">
                <div style="text-align: center;">
                  <a-textarea
                      style="width: 100%"
                      v-model="remarks"
                      placeholder="输入故障审核备注"
                      :auto-size="{ minRows: 6, maxRows: 6 }"
                  />
                  <div style="margin-top: 10px">
                    <a-button @click="handleFaultAudit(2)" type="primary" style="margin-right: 10px">审核通过</a-button>
                    <a-button @click="handleFaultAudit(3)" type="danger">审核驳回</a-button>
                  </div>
                </div>
              </div>
            </template>
          </div>

        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/inspect/inspectVehicle'

import {MultipleUpload, UploadFile, UploadImage} from '@/components'
import {isEmpty, isVideo} from '@/utils/util'
import html2canvas from 'html2canvas'

export default {
  props: {
    inspect_type: {
      type: Number,
      default: 0
    }
  },
  components: {
    UploadFile,
    UploadImage,
    MultipleUpload
  },
  data() {
    const storeInfo = this.$store.getters.storeInfo
    return {
      storeInfo,
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前记录
      record: {},

      statusArray: ['未入职', '已入职'],

      errlist: [],

      remarks: ''
    }
  },
  methods: {
    isEmpty,
    isVideo,

    download() {
      const that = this
      html2canvas(this.$refs.picture, {
        useCORS: true // 开启 CORS 功能
      }).then(canvas => {
        // 转成图片，生成图片地址
        var imgUrl = canvas.toDataURL('image/png')
        var eleLink = document.createElement('a')
        eleLink.href = imgUrl // 转换后的图片地址
        eleLink.download = that.title
        // 触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      })
    },

    /**
     * 显示对话框
     */
    open(item) {
      this.record = item

      var errlist = []
      this.record.ic_list.forEach(item => {
        item.inspect_list.forEach(item2 => {
          if (item2.check == 1) {
            errlist.push(item2.project_name)
          }
        })
      })
      console.log(this.storeInfo)

      this.remarks = this.record.fault_remarks ? this.record.fault_remarks : this.record.remarks

      this.errlist = errlist
      this.title = this.storeInfo.corporate_name + '-车辆' + this.record.vehicle.vehicle_name + '检查记录'
      this.visible = true
    },

    handleAudit(status) {
      const postData = {
        iv_id: this.record.iv_id,
        form: {
          status,
          remarks: this.remarks,
        }
      }
      Api.auditInspectVehicle(postData).then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSuccess')
      })
    },

    handleFaultAudit(status) {
      const postData = {
        iv_id: this.record.iv_id,
        form: {
          fault_status: status,
          fault_remarks: this.remarks,
        }
      }
      Api.faultAuditInspectVehicle(postData).then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSuccess')
      })
    },


    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">

.image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px #e8e8e8 solid;
  background-size: cover;
}

.title {
  font-size: 30px;
  padding: 40px 0;
  text-align: center;
  font-weight: bold;
}

.table {
  border: 1px #e8e8e8 solid;
  text-indent: 10px;


  .tr {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      //padding: 0 10px;
      min-height: 40px;
      line-height: 40px;

      width: 100%;
      //height: 100%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .std {
        border-bottom: 1px #d9d9d9 solid;
        min-height: 40px;

        &:last-child {
          border-bottom: none;
        }

        .sstd {
          min-height: 40px;

          .image {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: 1px #e8e8e8 solid;
            background-size: cover;
            margin: 0 2px;
          }

          display: flex;
          justify-content: center;

          div {
            width: 100%;
          }
        }
      }
    }

    .f600 {
      font-weight: 600;
    }

    .video {
      display: flex;
      justify-content: center;

      .image {
        margin-right: 5px;
      }
    }
  }
}

</style>
