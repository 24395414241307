<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item :label="modStr+'编号'" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              :placeholder="'请输入'+modStr+'编号'"
              v-decorator="['inspect_number', {rules: [{required: true, message: '请输入'+modStr+'编号'}]}]"
          />
        </a-form-item>
        <a-form-item v-if="inspect_type==0" :label="modStr+'周期'" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-range-picker
              style="width: 100%"
              v-decorator="['time_frame', {rules: [{required: true, message: '请选择'+modStr+'周期'}]}]"
          />
        </a-form-item>
        <a-form-item :label="modStr+'内容'" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              :filter-option="filterOption"
              v-decorator="['ico_id',{rules: [{required: true, message: '请选择'+modStr+'内容'}]}]"
              :placeholder="'请选择'+modStr+'内容'"
          >
            <a-select-option v-for="item in content_list" :value="item.ico_id" :item="item">
              {{ item.content_name }}
            </a-select-option>
          </a-select>
          <!--          <SelectInspectProjectTree-->
          <!--              placeholder="请选择检查项目"-->
          <!--              v-decorator="['icp_ids', {rules: [{required: true, message: '请选择检查项目'}]}]"-->
          <!--          />-->
        </a-form-item>
        <a-form-item label="参与车辆" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="inspect_type===1">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['vehicle_ids',{rules: [{required: true, message: '请选择车辆'}]}]"
                placeholder="请选择车辆"
            >
              <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                {{ item.vehicle_name }}
              </a-select-option>
            </a-select>
          </template>
          <template v-else>
            <a-select
                allowClear
                mode="multiple"
                show-search
                :filter-option="filterOption"
                v-decorator="['vehicle_ids',{initialValue:vehicleDefaultValue,rules: [{required: true, message: '请选择车辆'}]}]"
                placeholder="请选择车辆"
            >
              <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                {{ item.vehicle_name }}
              </a-select-option>
            </a-select>
          </template>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as inspectContentApi from '@/api/inspect/inspectContent'
import * as vehicleApi from "@/api/vehicle";
import * as Api from '@/api/inspect'
import _ from "lodash";
import {SelectInspectProjectTree} from '@/components'

export default {
  props: {
    inspect_type: {
      type: Number,
      default: 0
    }
  },
  components: {
    SelectInspectProjectTree
  },
  data() {
    return {
      modStr: this.inspect_type == 1 ? '抽查' : '检查',
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      project_list: [{}],
      // 车辆列表
      vehicle_list: [],
      // 分类列表
      content_list: [],

      vehicleDefaultValue: []
    }
  },
  created() {
    // 分类信息
    this.getInspectContent()
    // 车辆信息
    this.getVehicleList()
  },
  methods: {
    getInspectContent() {
      inspectContentApi.allList().then(({data: {list}}) => {
        this.content_list = list
      })
    },
    /**
     * 获取车辆信息
     */
    getVehicleList() {
      vehicleApi.getSelectList().then(({data: {list}}) => {
        this.vehicle_list = list

        this.vehicleDefaultValue = list.map(item => {
          return item.vehicle_id
        })
      })
    },

    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAdd() {
      this.project_list.push({})
    },
    handleDel(index) {
      if (this.project_list.length <= 1) {
        this.$message.error('至少保留一项')
        return
      }
      this.project_list.splice(index, 1)
    },

    /**
     * 新增对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增' + this.modStr

      this.visible = true
    },

    /**
     * 编辑对话框
     */
    edit(record) {
      this.mode = "edit"
      this.title = '编辑' + this.modStr
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        this.record.project = this.record.project_array
        const data = _.pick(this.record, ['days', 'status'])
        setFieldsValue(data)

        var list = []
        this.project_list = this.record.project_list
        this.record.project_list.forEach((item, index) => {
          list['project.' + index + '.project_name'] = item.project_name
        })
        this.$nextTick(() => {
          const data2 = _.pick(list, Object.keys(list))
          setFieldsValue(data2)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.inspect_type = this.inspect_type
        if (this.inspect_type === 1) {
          values.vehicle_ids = [values.vehicle_ids]
        }
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({inspectId: this.record['inspect_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
